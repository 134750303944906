body {
  margin: 0;
  display: flex;
  justify-content: center;
  --lightColor: rgb(250, 225, 200);
  --baseColor: rgb(90, 100, 75);
  --darkColor:rgb(47, 53, 38);
  --blackColor: rgb(9,25,0);
  --lightGray: rgb(140, 140, 140);
  --darkGray:rgb(80, 80, 80);
  background: var(--blackColor);
}

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

ol, ul {
padding: 0;
list-style: none;
}

button {
  cursor: pointer;
  border: none;
}

