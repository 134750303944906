.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--baseColor);
  min-height: 100vh;
  width: 100vw;
  max-width: 600px;
  min-width: 300px;
  & h2{
    text-align: center;
    font-size: 2rem;
    color: var(--lightColor);
    background: var(--darkColor);
  }
}